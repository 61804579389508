import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://6de3f8647c017a9a32adedd1c48bb924@o4507489933066240.ingest.us.sentry.io/4507489943617536",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});